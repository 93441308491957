import React from 'react';

import { Banner } from './components/banner/Banner';
import { NewsAndEvents } from './components/news-and-resources/NewsAndEvents';

import cn from './AboutTemplate.module.scss';

export default () => {
  return (
    <div className={cn.page}>
      <Banner />
      <NewsAndEvents />
    </div>
  );
};
