import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { Footer } from './components/footer/Footer';
import { Header } from './components/header/Header';
import AboutTemplate from './pages/about/AboutTemplate';
import ApplicationTemplate from './pages/home/HomeTemplate';

import cn from './App.module.scss';

export const App = () => {
  return (
    <BrowserRouter>
      <div className={cn.page}>
        <Header />
        <Routes>
          <Route index element={<ApplicationTemplate />} />
          <Route path="/about" element={<AboutTemplate />} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
};
