import React from 'react';

import cn from './JeremyQuote.module.scss';

export const JeremyQuote = () => {
  return (
    <>
      <div className={cn.card}>
        <div className={cn.info_container}>
          <div className={cn.grow} />
          <div className={cn.info}>
            <p tabIndex={-1} className={cn.description}>
              We built More Positiv to help people cut through the noise and remember that despite the challenges we face, there is still so much good
              in the world. In a time when news cycles often feel overwhelming and divisive, we provide a reliable source of optimism. Life is more
              than the headlines, and at More Positiv, we aim to bring that truth to light.
            </p>
            <p tabIndex={-1} className={cn.author}>
              <strong>Jeremy Fain</strong>
              <br />
              CEO and Co-Founder of Cognitiv
              <br />
              (the company behind More Positiv)
            </p>
          </div>
          <div className={cn.grow} />
        </div>
        <a tabIndex={-1} aria-label="Jeremy - CEO of Cognitiv" target="_blank" rel="noreferrer" className={cn.img_container}>
          <div
            className={cn.img}
            style={{
              backgroundImage: `url("/jeremy.png")`,
            }}
            role="img"
            aria-label="Jeremy - CEO of Cognitiv"
          />
        </a>
      </div>
    </>
  );
};
