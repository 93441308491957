import React from 'react';

import { Articles } from './components/articles/Articles';
import { Banner } from './components/banner/Banner';
import { PositivPulse } from './components/positiv-pulse/PositivPulse';

export default () => {
  return (
    <>
      <PositivPulse />
      <Banner />
      <Articles />
    </>
  );
};
