import React, { useCallback } from 'react';

import cn from './Banner.module.scss';

export const Banner = () => {
  const onClickDownload = useCallback(() => {
    const fileUrl = '/Cognitiv_MorePositiv_Positioning.pdf';
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', 'More Positiv Overview.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  return (
    <div className={cn.banner}>
      <h2 className={cn.subtitle}>About More Positiv</h2>
      <p>
        At More Positiv, we believe in the power of uplifting stories. Every day, we curate the most inspiring breakthroughs, discoveries, and human
        triumphs from across the internet-bringing you a refreshing perspective on the news. Join us in celebrating the brighter side of news!
      </p>
      <p>
        Our Methodology: Powered by Cognitiv's advanced AI, we analyze thousands of articles, using large language models (LLMs) to objectively score
        content sentiment and surface only the most positive stories. The Positiv News Pulse takes this a step further, providing real-time sentiment
        tracking across news cycles. By continuously monitoring shifts in media tone, it uncovers key moments that shape public perception and
        influence broader trends.
      </p>
      <p>
        <strong>
          <span className={cn.download} onClick={onClickDownload}>
            Download our overview
          </span>{' '}
          to learn more about how AI is bringing you brighter news.
        </strong>
      </p>
    </div>
  );
};
