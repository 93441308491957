import React, { useCallback, useState } from 'react';

import { Article } from '../articles/Articles';

import cn from './ArticleCard.module.scss';

export const ArticleCard = ({ article, place }: { article: Article; place: number }) => {
  const { Title, Images, SiteName, Url, DaysOnList } = article;
  const [usePlaceholder, setUsePlaceholder] = useState(false);

  const onClickArticle = useCallback(() => {
    if (typeof gtag === 'function') {
      gtag('event', 'click', {
        send_to: 'G-TG1B5FR0SL',
        event_category: 'user interaction',
        event_label: `${Title}: ${Url}`,
      });
    }
  }, [Title, Url]);

  const handleImageError = () => {
    setUsePlaceholder(true);
  };

  return (
    <div className={cn.card}>
      <a href={Url} aria-label={Title} target="_blank" rel="noreferrer" onClick={onClickArticle} className={cn.img_container}>
        <div
          className={cn.img}
          style={{
            backgroundImage: `url("${usePlaceholder ? '/cognitiv-positiv-logo.png' : Images[0]}")`,
            backgroundSize: usePlaceholder ? '60%' : 'cover',
            backgroundColor: usePlaceholder ? '#015BD7' : '',
            backgroundPosition: usePlaceholder ? 'center' : 'top',
          }}
          role="img"
          aria-label={Title}
        />
        <p className={cn.place}>{place}</p>
      </a>
      <img src={Images[0]} alt={Title} style={{ display: 'none' }} onError={handleImageError} />
      <div className={cn.info}>
        <a href={Url} aria-label={Title} target="_blank" rel="noreferrer" onClick={onClickArticle}>
          <h3 className={cn.title} title={Title}>
            {Title}
          </h3>
        </a>
        <a
          href={Url}
          aria-label={`${SiteName} - ${DaysOnList} ${DaysOnList === 1 ? 'Day' : 'Days'} on List`}
          target="_blank"
          rel="noreferrer"
          onClick={onClickArticle}
          className={cn.source}
        >
          <span className={cn.site_name}>{SiteName}</span>
          {DaysOnList && ` (${DaysOnList} ${DaysOnList === 1 ? 'Day' : 'Days'} on List)`}
        </a>
      </div>
    </div>
  );
};
