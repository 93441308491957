import React, { ReactNode } from 'react';
import Slider from 'react-slick';

import { EventCard } from '../events/EventCard';
import { JeremyQuote } from '../jeremy-quote/JeremyQuote';
import { ResourceCard } from '../resource-card/ResourceCard';

import cn from './NewsAndEvents.module.scss';

export interface Resource {
  title: string;
  url: string;
  site_name: string;
  image: string;
}

export interface Event {
  title: ReactNode;
  date?: string;
  description: ReactNode;
  url?: string;
  site_name: string;
  image: string;
}

export const NewsAndEvents = () => {
  const resources: Resource[] = [
    {
      title: 'The Top 10 Ways to Use AI in 2025: AI can reduce stress, boost productivity, and offer mental clarity.',
      url: 'https://www.cognitiv.ai/post/top-10-ways-to-use-ai-in-2025',
      site_name: 'READ MORE',
      image: '/laptop-lady.png',
    },
    {
      title: 'CBS Virginia This Morning: 10 Ways AI Can Make Your Life Easier',
      url: 'https://www.wtvr.com/on-air/virginia-this-morning/10-ways-ai-can-make-your-life-easier#google_vignette',
      site_name: 'WATCH HERE',
      image: '/ten-ways-ai.png',
    },
  ];

  const events: Event[] = [
    {
      title: (
        <>
          <em>Find Your Positiv</em> Event
        </>
      ),
      date: 'May 28, 2025',
      description: (
        <>
          Join us at the More Positiv - Find Your Positiv pop-up event at Grand Central Station. Step into our vibrant lounge to share your "Key to
          Positivity" through interactive experiences. In honor of Mental Health Awareness Month, we're proudly donating to Mental Health America
          (MHA). It's more than an event; it's a celebration of the power of positivity.
        </>
      ),
      site_name: 'RSVP',
      image: '/grand-central.png',
    },
  ];

  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <div />,
    nextArrow: <div />,
  };

  return (
    <>
      <div className={cn.container}>
        <h2 className={cn.header}>Positiv News and Resources</h2>
        {!!resources.length && (
          <div className={cn.grid} style={{ gridTemplateColumns: resources.length > 1 ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)' }}>
            {resources.map((resource, index) => (
              <ResourceCard key={index} resource={resource} />
            ))}
          </div>
        )}
        {!!events.length && (
          <div className={cn.events}>
            {events.length ? (
              <Slider {...settings}>
                {events.map((event, index) => (
                  <EventCard key={index} event={event} />
                ))}
                <JeremyQuote />
              </Slider>
            ) : (
              <EventCard event={events[0]} />
            )}
          </div>
        )}
      </div>
    </>
  );
};
