import React from 'react';

import { Event } from '../news-and-resources/NewsAndEvents';

import cn from './EventCard.module.scss';

export const EventCard = ({ event }: { event: Event }) => {
  const { title, date, description, image, site_name, url } = event;

  return (
    <>
      <div className={cn.card}>
        <div className={cn.info_container}>
          <div className={cn.header}>
            <h2>More Positiv in Action</h2>
          </div>
          <div className={cn.grow} />
          <div className={cn.info}>
            <div>
              <h3 className={cn.title}>{title}</h3>
              {!!date && <div className={cn.date}>{date}</div>}
            </div>
            <div className={cn.description}>{description}</div>
            {url && <div className={cn.source}>{site_name}</div>}
          </div>
          <div className={cn.grow} />
        </div>
        <div className={cn.img_container}>
          <div
            className={cn.img}
            style={{
              backgroundImage: `url("${image}")`,
            }}
            role="img"
            aria-label="Event Image"
          />
        </div>
        <div className={cn.header_mobile}>
          <h2>More Positiv in Action</h2>
        </div>
      </div>
    </>
  );
};
