import React, { useCallback, useState } from 'react';

import { Resource } from '../news-and-resources/NewsAndEvents';

import cn from './ResourceCard.module.scss';

export const ResourceCard = ({ resource }: { resource: Resource }) => {
  const { title, url, site_name, image } = resource;
  const [usePlaceholder, setUsePlaceholder] = useState(false);

  const onClickArticle = useCallback(() => {
    if (typeof gtag === 'function') {
      gtag('event', 'click', {
        send_to: 'G-TG1B5FR0SL',
        event_category: 'user interaction',
        event_label: `${title}: ${url}`,
      });
    }
  }, [title, url]);

  const handleImageError = () => {
    setUsePlaceholder(true);
  };

  return (
    <div className={cn.card}>
      <a href={url} aria-label={title} target="_blank" rel="noreferrer" onClick={onClickArticle} className={cn.img_container}>
        <div
          className={cn.img}
          style={{
            backgroundImage: `url("${usePlaceholder ? '/cognitiv-positiv-logo.png' : image}")`,
            backgroundSize: usePlaceholder ? '60%' : 'cover',
            backgroundColor: usePlaceholder ? '#015BD7' : '',
            backgroundPosition: usePlaceholder ? 'center' : 'top',
          }}
          role="img"
          aria-label={title}
        />
      </a>
      <img src={image} alt={title} style={{ display: 'none' }} onError={handleImageError} />
      <div className={cn.info}>
        <a href={url} aria-label={title} target="_blank" rel="noreferrer" onClick={onClickArticle}>
          <h3 className={cn.title} title={title}>
            {title}
          </h3>
        </a>
        <a href={url} aria-label={site_name} target="_blank" rel="noreferrer" onClick={onClickArticle} className={cn.source}>
          <span className={cn.site_name}>{site_name}</span>
        </a>
      </div>
    </div>
  );
};
