import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { EnjoyCard } from '../../pages/home/components/enjoy-card/EnjoyCard';
import { Modal } from '../../pages/home/components/modal/Modal';
import { SubscribeCard } from '../../pages/home/components/subscribe-card/SubscribeCard';

import cn from './Header.module.scss';

export const Header: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [subscribe_open, setSubscribeOpen] = useState(false);
  const [enjoy_open, setEnjoyOpen] = useState(false);
  const subscribeOpenRef = useRef(false);
  const [top, setTop] = useState(0);
  const lastScrollTop = useRef(0);

  const today = new Date();
  const formattedDate = today.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

  useEffect(() => {
    if (subscribe_open) {
      subscribeOpenRef.current = true;
    }
  }, [subscribe_open]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
      const headerHeight = document.getElementById('header')?.offsetHeight || 0;

      if (currentScroll <= 0) {
        setTop(0);
      } else if (currentScroll > lastScrollTop.current) {
        setTop((prev) => Math.min(prev + (currentScroll - lastScrollTop.current), headerHeight));
      } else {
        setTop((prev) => Math.max(prev - (lastScrollTop.current - currentScroll), 0));
      }

      lastScrollTop.current = currentScroll;
    };

    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    if (isMobile) {
      setTimeout(() => {
        if (!subscribeOpenRef.current) {
          setEnjoyOpen(true);
        }
      }, 6000);
    }

    const handleMouseMove = (e: MouseEvent) => {
      if (isMobile || subscribeOpenRef.current) {
        window.removeEventListener('mousemove', handleMouseMove);
        return;
      }
      if (e.clientY < 50) {
        setEnjoyOpen(true);
        window.removeEventListener('mousemove', handleMouseMove);
      }
    };

    window.addEventListener('scroll', handleScroll);

    const mouseMoveTimeout = setTimeout(() => {
      window.addEventListener('mousemove', handleMouseMove);
    }, 3000);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('mousemove', handleMouseMove);
      clearTimeout(mouseMoveTimeout);
    };
  }, []);

  const onClickPositivLogo = useCallback(() => {
    if (pathname === '/') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      navigate('/');
    }
  }, [navigate, pathname]);

  return (
    <div id="header" className={cn.header} style={{ top: `-${top}px` }}>
      <img className={cn.logo} src="/cognitiv-positiv-logo.png" alt="cognitiv positiv logo" onClick={onClickPositivLogo} />
      <div className={cn.menu}>
        <p className={cn.date}>{formattedDate}</p>
        <Link className={cn.about} to="./about">
          ABOUT
        </Link>
        <button className={cn.subscribe} onClick={() => setSubscribeOpen(true)}>
          SUBSCRIBE
        </button>
      </div>
      <Modal open={subscribe_open} onClose={() => setSubscribeOpen(false)} background="#ffe21f">
        <SubscribeCard />
      </Modal>
      <Modal open={enjoy_open} onClose={() => setEnjoyOpen(false)} background="#ffe21f">
        <EnjoyCard />
      </Modal>
    </div>
  );
};
